/* eslint-disable comma-dangle */
import { lazy } from 'react';

const routes = [
  // {
  //   path: '/dashboard',
  //   element: lazy(() => import('../pages/admin/Home')),
  //   auth: true
  // },
  {
    path: '/approvals',
    element: lazy(() => import('../pages/admin/Requests')),
    auth: true
  },
  {
    path: '/subscriptions',
    element: lazy(() => import('../pages/subscriptions/List')),
    auth: true
  },
  {
    path: '/create-subscriptions',
    element: lazy(() => import('../pages/subscriptions/CreateSubscription')),
    auth: true
  },
  {
    path: '/user-subscriptions',
    element: lazy(() => import('../pages/userSubscriptions/List')),
    auth: true
  },
  {
    path: '/coupons',
    element: lazy(() => import('../pages/coupons/List')),
    auth: true
  },
  {
    path: '/create-coupons',
    element: lazy(() => import('../pages/coupons/CreateCoupons')),
    auth: true
  },
];
export default routes;
